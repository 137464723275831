<template>
  <div class="transfer-client-owner">
    <!-- 提交进行翻译 -->
    <el-dialog
      :visible.sync="transferOwnerDialog"
      top="10%"
      :title="$t('label.knowledgebase.submit.for.translation')"
      width="55%"
      :close-on-click-modal="false"
      :before-close="submittranslationhandleClose"
    >
      <div>
        <!-- 选择目标语言，然后将文章分配给人员或队列。翻译导出通常分配给一个队列，内部翻译的文章通常分配给一个人。 -->
        {{ $t("label.knowledgebase.assign.articles.to.people.or.queues") }}
      </div>
      <div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <!-- 语言 -->
          <el-table-column
            prop="language"
            :label="$t('label.language')"
            width="120"
          >
          </el-table-column>
          <!-- 被分配人 -->
          <el-table-column :label="$t('component.event.label.person')">
            <template slot-scope="scope">
              <div style="position: relative">
                <el-select
                  v-model="scope.row.ownerType"
                  @change="changestate(scope.row)"
                  filterable
                  :placeholder="$t('label.select.please')"
                  v-if="isQueue || isPartner"
                  style="width: 120px"
                >
                  <el-option
                    v-for="item in ownerTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    v-if="
                      item.value === 'user' ||
                      (item.value === 'queue' && isQueue) ||
                      (item.value === 'partneruser' && isPartner)
                    "
                  >
                  </el-option>
                </el-select>
                <!-- 队列选项 -->
                <el-select
                  v-model="scope.row.value"
                  clearable
                  filterable
                  :placeholder="$t('label.select.please')"
                  v-if="scope.row.ownerType === 'queue'"
                  style="display: inline-block; width: calc(100% - 120px)"
                >
                  <el-option
                    v-for="item in ownerQueue"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <!-- 查找/查找多选字段 -->
                <el-select
                  clearable
                  v-else
                  v-model="scope.row.value"
                  @focus="focus(scope.row)"
                  class="no-suffix"
                  :style="{
                    width: isQueue || isPartner ? 'calc(100% - 120px)' : '100%',
                  }"
                >
                  <p class="searchTipBox">
                    <span
                      @click="remoteSearchOwner"
                      style="display: inline-block; width: 100%"
                    >
                      <i class="el-icon-search"></i>
                      <span style="padding-left: 10px">
                        <!-- 前往精准搜索 -->
                        {{
                          $t("vue_label_commonobjects_detail_to_precise_search")
                        }}
                      </span>
                    </span>
                  </p>
                  <el-option
                    v-for="item in scope.row.data"
                    v-show="ownerName !== ''"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <span class="remoteBtn" v-if="scope.row.ownerType !== 'queue'">
                  <i class="el-icon-search"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <!-- 到期日期 -->
          <el-table-column
            prop="name"
            :label="$t('label.quickbooks.viewlist.duedate')"
            width="300"
          >
            <template slot-scope="scope">
              <!-- 选择日期 -->
              <el-date-picker
                v-model="scope.row.assignment_due_date"
                value-format="yyyy-MM-dd"
                type="date"
                :placeholder="$t('label.tabpage.selectdate')"
              >
              </el-date-picker>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top: 17px" v-if="ownerType !== 'queue'">
        <template>
          <el-checkbox v-model="isSendMail">
            <span style="padding-left: 6px">
              <!-- 发送电子邮件 -->
              {{ $t("page.send.email") }}
            </span>
          </el-checkbox>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submittranslationhandleClose" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="submittranslationsure" size="mini" type="primary">
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="checked"
        :ownerType="ownerType"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        :isKnowledge="isKnowledge"
        @changeSelect="changeSelectOwner"
      />
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import * as knowledgearticlesApi from "./api.js";
export default {
  components: {},
  props: {
    id: {
      type: String,
      default: "",
    },
    ids: {
      type: String,
      default: "",
    },
    transferOwnerDialog: {
      type: Boolean,
      default: false,
    },
    // 对象id
    objId: {
      type: String,
      default: "",
    },
    // 是否显示统计条数
    showSelectNum: {
      type: Boolean,
      default: false,
    },
    selectNum: {
      type: Number,
      default: 0,
    },
    tableData: {
      type: Array,
    },
  },
  data() {
    return {
      multipleSelection: [],
      textarea: "",
      value1: "",
      sendMail: false,
      ownerId: "",
      ownerName: "",
      checked: false,
      fieldId: "",
      relevantObjId: "user",
      relevantPrefix: "",
      showSearchTable: false,
      checkList: [],
      isSendMail: false,
      ownerQueue: [],
      isPartner: false,
      isQueue: false,
      ownerType: "user",
      ownerTypeList: [
        {
          value: "user",
          // 用户
          label: this.$i18n.t("label.user"),
        },
        {
          value: "queue",
          // 队列
          label: this.$i18n.t("label.queue"),
        },
        {
          value: "partneruser",
          // 伙伴云用户
          label: this.$i18n.t("label.partneruser"),
        },
      ],
      obj: null,
      languageList: [],
      isKnowledge: "true",
    };
  },
  mounted() {
    this.ownerId = "";
    this.ownerName = "";
    this.ownerType = "user";
    this.checkList = [];
  },
  methods: {
    submittranslationsure() {
      let data = [];
      for (let i in this.multipleSelection) {
        data.push({
          assign_toid: this.multipleSelection[i].value,
          assignment_due_date: this.multipleSelection[i].assignment_due_date,
          language: this.multipleSelection[i].language,
          default_reviewer: this.multipleSelection[i].default_reviewer,
          assignee_ownertype: this.multipleSelection[i].ownerType,
          reviewer_ownertype: this.multipleSelection[i].reviewer_ownertype,
        });
      }
      let params = {
        data: JSON.stringify(data),
        sendEmail: this.isSendMail,
      };
      if (this.id) {
        let data = [];
        data.push(this.id);
        params.id = JSON.stringify(data);
      } else {
        params.id = JSON.stringify(this.ids);
      }
      knowledgearticlesApi.translate(params).then((res) => {
        if (res.data.failCount > 0) {
          // {a}条数据翻译失败！
          this.$message.error(
            res.data.failCount +
              this.$i18n.t("label.knowledgebase.data.translation.failed", [
                res.data.failCount,
              ])
          );
        }
        if (res.data.succeedCount > 0) {
          setTimeout(() => {
            // {a}条数据翻译成功！
            this.$message.success(
              res.data.succeedCount +
                this.$i18n.t(
                  "label.knowledgebase.data.translated.successfully",
                  [res.data.succeedCount]
                )
            );
          }, 1000);
        }

        this.$emit("refreshright");
        this.$emit("refreshpage");
        this.submittranslationhandleClose();
      });
    },
    changestate(row) {
      this.ownerType = row.ownerType;
      if (row.ownerType !== "user") {
        for (let i in this.tableData) {
          if (row.id == this.tableData[i].id) {
            this.tableData[i].value = "";
            this.tableData[i].data.id = "";
            this.tableData[i].data.name = "";
            this.tableData[i].ownerType = row.ownerType;
          }
        }
      } else {
        // this.ownerType = row.ownerType;
        // for (let i in this.tableData) {
        //   if (row.id == this.tableData[i].id) {
        //     this.tableData[i].data[0].id = localStorage.getItem("userId");
        //     this.tableData[i].data[0].name = localStorage.getItem("username");
        //     this.tableData[i].value = localStorage.getItem("userId");
        //   }
        // }
      }
    },
    focus(row) {
      this.obj = row;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查询队列数据
    queryQueue() {
      knowledgearticlesApi.queryQueue({ id: this.objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.ownerQueue = res.data === null ? [] : res.data;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 更改所有人是否展示伙伴云和队列
    checkQueueAndPartner() {
      knowledgearticlesApi.checkQueueAndPartner({ id: this.objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.isPartner = res.data.isPartner || false;
          this.isQueue = res.data.isQueue || false;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    // 查找所有人
    remoteSearchOwner() {
      // 查找所有人数据写死
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    changeSelectOwner(row) {
      this.ownerName = row.data && row.data.name ? row.data.name : "";
      // this.obj.data.id = row.data.id
      // this.obj.data.name = row.data.name
      // this.obj.value = row.data && row.data.id ? row.data.id : "";
      this.showSearchTable = false;
      for (let i in this.tableData) {
        if (this.obj.id == this.tableData[i].id) {
          if(row.data){
            this.tableData[i].data[0].id = row.data.id;
          this.tableData[i].data[0].name = row.data.name;
          this.tableData[i].value = row.data.id;
          }else{
            this.tableData[i].data[0].id = "";
          this.tableData[i].data[0].name = "";
          this.tableData[i].value = "";
          }
        }
      }
      this.ownerType = "user";
    },
    submittranslationhandleClose() {
      this.ownerId = "";
      this.ownerName = "";
      this.ownerType = "user";
      this.checkList = [];
      this.isSendMail = false;
      this.$emit("submittranslationhandleClose");
    },
  },
  computed: {
    // sendMail: function () {
    //   if (this.ownerType !== 'queue') {
    //     return this.isSendMail
    //   } else {
    //     return ''
    //   }
    // }
  },
  watch: {
    transferOwnerDialog(nval) {
      if (nval) {
        this.checkQueueAndPartner();
      }
    },
    ownerType(nval) {
      this.ownerId = "";
      this.ownerName = "";
      if (nval === "queue") {
        this.$nextTick(() => {
          this.queryQueue();
        });
      }
    },
    // tableData(){
    //   
    // }
  },
};
</script>

<style scoped lang="scss">
.transfer-client-owner ::v-deep .el-dialog__body {
  min-height: 350px;
  max-height: 350px;
}
// 查找下拉框不显示下拉图标
::v-deep .no-suffix .el-input__suffix {
  display: none;
}
.remoteBtn {
  position: absolute;
  right: 8px;
  top: 4px;
  color: #c0c4cc;
  z-index: 100;
}
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
}

::v-deep .el-input__inner {
  height: 30px;
}

.selectnum {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}

.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
</style>
