import axios from "@/config/httpConfig";
// 提交翻译
export function translate(data) {
    return axios.post("/knowledgeTranslate/translate", data);
}
// 队列
export function queryQueue(data) {
    return axios.post('/objectdetail/queryQueue', data)
}
// 更改所有人是否展示伙伴云和队列
export function checkQueueAndPartner(data) {
    return axios.post('/objectdetail/checkQueueAndPartner', data)
}  